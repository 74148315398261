import React, { useState } from "react"

import Img from "../images/portfolio.png"

const PortfolioInfo = [
  {
    id: 1,
    src: "https://www.youtube.com/embed/KkWhOupoGF8",
  },
  {
    id: 2,
    src: "https://www.youtube.com/embed/q_ItEKMuqtk",
  },
  {
    id: 3,
    src: "https://www.youtube.com/embed/51-GY7nMg0M",
  },
  {
    id: 4,
    src: "https://www.youtube.com/embed/GHJvf85_K7U",
  },
  {
    id: 5,
    src: "https://www.youtube.com/embed/NT3aGqpZQO8",
  },
  {
    id: 6,
    src: "https://www.youtube.com/embed/PwlF1O75DCg",
  },
  {
    id: 7,
    src: "https://www.youtube.com/embed/KmoQRqVK31Y",
  },
]
const calculateslices = PortfolioInfo.length / 2

const Portfolio = () => {
  const [slices, setSlices] = useState(3)

  const addSlices = () => {
    setSlices(PortfolioInfo.length)
  }
  const removeSlices = () => {
    setSlices(3)
  }

  return (
    <div className="grid-container">
      <p className="intro-text">
        Musik erfüllt mein Leben in jeglicher Hinsicht. Ich bin schon lange
        Leadsinger in einer Band und konnte schon viele Erfahrungen als Sängerin
        sammeln. Musik geht direkt ins Herz. Überzeuge dich selbst
      </p>
      <div className="grid-x grid-margin-x align-center">
        {PortfolioInfo.slice(0, slices).map(info => {
          return (
            <div key={info.id} className="large-4 cell medium-6">
              <div className="portfolio__video-container">
                <div className="video-wrapper">
                  <iframe
                    className="video-embed"
                    width="370"
                    height="228"
                    src={info.src}
                    frameBorder="0"
                    title="video"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {calculateslices === 3 ? null : (
        <div>
          {slices < PortfolioInfo.length ? (
            <div className="text-center">
              <button className="btn-showmore" onClick={addSlices}>
                Mehr anzeigen
              </button>
            </div>
          ) : (
            <div className="text-center">
              <button className="btn-showmore" onClick={removeSlices}>
                Weniger anzeigen
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Portfolio
