import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <div className="footer">
      <p>© Blessing 2020</p>
      <p>
        <Link className="footer__link" to="/impressum">
          Impressum
        </Link>{" "}
        |{" "}
        <Link className="footer__link" to="/datenschutz">
          Datenschutz
        </Link>
      </p>
    </div>
  )
}

export default Footer
