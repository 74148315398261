import React from "react"

const Contact = () => {
  return (
    <div className="grid-container">
      <p className="intro-text">Ich freue mich auf eine Nachricht von dir!</p>
      <div className="contact" id="contact">
        <div className="contact__content">
          <form
            className="contact__form"
            name="contact"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            action="/success"
          >
            <div className="grid-x grid-margin-x">
              <div className="large-6 cell medium-6">
                <label>
                  Dein Name: <input type="text" name="name" required />
                </label>
              </div>
              <div className="large-6 cell medium-6">
                <label>
                  Deine E-mail: <input type="email" name="email" required />
                </label>
              </div>
            </div>
            <label>
              Deine Nachricht:{" "}
              <textarea rows="4" cols="40" name="message" required></textarea>
            </label>
            <div className="text-center">
              <button className="btn-showmore" type="submit">
                Kontakt aufnehmen
              </button>
            </div>
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>
                Don’t fill this out: <input name="bot-field" />
              </label>
            </p>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Contact
