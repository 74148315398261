import React from "react"

import YT from "../images/yt.svg"
import Insta from "../images/insta.svg"

const Social = () => {
  return (
    <div className="social">
      <p className="social__text">
        <span>Folge mir</span>
      </p>
      <a
        href="https://www.instagram.com/blessing_weddingsinger/"
        target="_blank"
      >
        <img src={Insta}></img>
      </a>
      <a
        href="https://www.youtube.com/channel/UChC6ryWz0guUZmJZZT3xKfA"
        target="_blank"
      >
        <img src={YT}></img>
      </a>
    </div>
  )
}

export default Social
