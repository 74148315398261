import React from "react"

const Intro = props => {
  return (
    <div className="intro">
      <h2> {props.title}</h2>
      <hr></hr>
    </div>
  )
}

export default Intro
