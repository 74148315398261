import React from "react"
import Img from "gatsby-image"

const Aboutme = props => {
  return (
    <div className="grid-container">
      <div className="section">
        <div className="grid-x">
          <div className="large-6 cell">
            <Img fluid={props.image}></Img>
          </div>
          <div className="large-6 cell align-self-middle">
            <div className="aboutme">
              <p>
                Ich bin Samira, 25 Jahre alt und wohne im wunderschönen Raum
                Stuttgart
              </p>
              <p>
                Schon seit meiner Kindheit begleitet mich die Musik. Angefangen
                zu singen habe ich in einem renommierten Kinderchor. Dadurch
                konnte ich meine ersten Erfahrungen mit Bühnenauftritten sammeln
                und erhielt gleichzeitig eine Gesangsbildung.
              </p>
              <p>
                Das Spielen mehrerer Instrumente, wie beispielsweise Gitarre und
                Klavier, erlernte ich auf autodidaktische Art und Weise. Ich
                liebe es, Musik auf den unterschiedlichsten Wegen auszuleben und
                andere Menschen damit zum Träumen zu bringen.
              </p>
              <p>
                Mit Leidenschaft und Gefühl versuche ich jedes Lied zu einem
                besonderen Geschenk zu machen.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="grid-x flex-dir">
          <div className="large-6 cell">
            <Img fluid={props.image2}></Img>
          </div>
          <div className="large-6 cell align-self-middle">
            <div className="aboutme">
              <p>
                In der Band „Due Care“ singe ich seit ca. 3 Jahren. Wir lieben
                es zusammen zu jamen, Lieder zu schreiben und diese auf
                Konzerten und Auftritten zu performern.
              </p>
              <p>
                Ich höre selbst jegliche Art von Musik, was es mir ermöglicht
                verschiedene Stilrichtungen zu singen. Da ich mit meinem
                musikalischen Gehör nicht eingeschränkt bin, habe ich ein
                grenzenloses Repertoire an Liedern.
              </p>
              <p>
                Ich bin eine lebensfrohe Persönlichkeit und liebe Hochzeiten.
                Ich freue mich auf dich.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Aboutme
