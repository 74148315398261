import React from "react"

import Leistung from "../images/leistung.svg"
import Leistung1 from "../images/leistung1.svg"
import Leistung2 from "../images/leistung2.svg"
import Leistung3 from "../images/leistung3.svg"

const Info = [
  {
    id: 1,
    image: Leistung,
    title: "Gesang mit Playback",
    location: "Trauung, Sektempfang",
    songs: "(Liedwünsche willkommen)",
  },
  {
    id: 2,
    image: Leistung1,
    title: "Gesang mit Livemusik",
    location: "Trauung, Sektempfang",
    songs: "(Liedwünsche willkommen)",
  },
  {
    id: 3,
    image: Leistung2,
    title: "Musikalische Begleitung der Kirchenlieder",
    location: "Trauung",
    songs: "(Liedwünsche willkommen)",
  },
  {
    id: 4,
    image: Leistung3,
    title: "Einstündiges Abendprogramm mit Band",
    location: "Feier",
    songs: "",
  },
]

const Leistungen = () => {
  return (
    <div className="leistungen">
      <div className="grid-container">
        <p className="intro-text">
          Musik verbindet! Diese Leistungen verzaubern dich und deine Gäste.
          Wähle deine Wunschleistung/en und kontaktiere mich gerne.
        </p>
        <div className="grid-x grid-margin-x grid-margin-y align-center">
          {Info.map((info, index) => {
            if (index % 2 === 0) {
              return (
                <div key={info.id} className="large-6 cell medium-6">
                  <div className="leistung">
                    <div className="leistung__content">
                      <img className="leistung__img" src={info.image}></img>
                      <h3 className="leistung__title">{info.title}</h3>
                      <p className="leistung__location">{info.location}</p>
                      <p className="leistung__songs">{info.songs}</p>
                    </div>
                  </div>
                </div>
              )
            } else {
              return (
                <div key={info.id} className="large-6 cell medium-6">
                  <div className="leistung mtop">
                    <div className="leistung__content">
                      <img className="leistung__img" src={info.image}></img>
                      <h3 className="leistung__title">{info.title}</h3>
                      <p className="leistung__location">{info.location}</p>
                      <p className="leistung__songs">{info.songs}</p>
                    </div>
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default Leistungen
