import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"
import Header from "../components/header"
import Intro from "../components/intro"
import Portfolio from "../components/portfolio"
import Social from "../components/social"
import Leistungen from "../components/leistungen"
import Aboutme from "../components/aboutme"
import Contact from "../components/contact"
import Footer from "../components/footer"

const IndexPage = data => {
  return (
    <Layout>
      <SEO title="Samira Blessing" />
      <Nav />
      <Header />
      <Social />
      <div id="portfolio" className="section">
        <Intro title="Portfolio" />
        <Portfolio />
      </div>
      <div id="leistungen" className="section-big">
        <Intro title="Leistungen" />
        <Leistungen />
      </div>
      <div id="übermich" className="section-big">
        <Intro title="About me" />
        <Aboutme
          image={data.data.aboutMeImage.childImageSharp.fluid}
          image2={data.data.aboutMeImage1.childImageSharp.fluid}
        />
      </div>
      <div id="contact" className="section-big">
        <Intro title="Kontakt" />
        <Contact />
      </div>
      <Footer />
    </Layout>
  )
}

export const data = graphql`
  query MyQuery {
    aboutMeImage: file(relativePath: { eq: "bild1.png" }) {
      childImageSharp {
        fluid(maxWidth: 420, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutMeImage1: file(relativePath: { eq: "bild2.png" }) {
      childImageSharp {
        fluid(maxWidth: 420, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
