import React, { useRef, useEffect } from "react"

import Logo from "../images/logo.svg"
import Anchorlink from "./anchorlink"

const win = typeof window !== "undefined" && window

const Nav = () => {
  const inputEl = useRef(null)
  const line1 = useRef(null)
  const line2 = useRef(null)
  const mobileNav = useRef(null)

  useEffect(() => {
    stickyNav()
    window.addEventListener("scroll", stickyNav)

    return function cleanup() {
      window.removeEventListener("scroll", stickyNav)
    }
  })

  const stickyNav = () => {
    const scrollTop = win.pageYOffset

    // Sticky Nav
    if (scrollTop > 0) {
      inputEl.current.classList.add("is-sticky")
    } else if (scrollTop <= 0) {
      inputEl.current.classList.remove("is-sticky")
    }
  }

  const toggleMenu = () => {
    line1.current.classList.toggle("line1-active")
    line2.current.classList.toggle("line2-active")
    mobileNav.current.classList.toggle("mobile-nav-active")
  }

  const removeMenu = e => {
    e.preventDefault()
    line1.current.classList.remove("line1-active")
    line2.current.classList.remove("line2-active")
    mobileNav.current.classList.remove("mobile-nav-active")
  }

  return (
    <div>
      <div className="nav" ref={inputEl}>
        <Anchorlink href="/#top">
          <img className="nav__logo" src={Logo}></img>
        </Anchorlink>

        <div className="hamburger" onClick={toggleMenu}>
          <div ref={line1} className="line1"></div>
          <div ref={line2} className="line2"></div>
        </div>
        <div className="nav__list">
          <Anchorlink offset={100} class="nav-link" href="/#portfolio">
            Portfolio
          </Anchorlink>
          <Anchorlink offset={100} class="nav-link" href="/#leistungen">
            Leistungen
          </Anchorlink>
          <Anchorlink offset={100} class="nav-link" href="/#übermich">
            About me
          </Anchorlink>
          <Anchorlink offset={100} class="nav-link" href="/#contact">
            Kontakt
          </Anchorlink>
        </div>
      </div>
      <div className="mobile-nav" ref={mobileNav}>
        <ul onClick={e => removeMenu(e)}>
          <li>
            {" "}
            <Anchorlink offset={100} class="nav-link" href="/#portfolio">
              Portfolio
            </Anchorlink>
          </li>
          <li>
            {" "}
            <Anchorlink offset={100} class="nav-link" href="/#leistungen">
              Leistungen
            </Anchorlink>
          </li>
          <li>
            {" "}
            <Anchorlink offset={100} class="nav-link" href="/#übermich">
              About me
            </Anchorlink>
          </li>
          <li>
            {" "}
            <Anchorlink offset={100} class="nav-link" href="/#contact">
              Kontakt
            </Anchorlink>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Nav
